
window.onscroll = function() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
        document.getElementById('js-scrollup').classList.add('is-show');
    } else {
        document.getElementById('js-scrollup').classList.remove('is-show');
    }
};

document.getElementById('js-scrollup').addEventListener('click', () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
});